import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Translation from "../../i18n";
import SenwellLogo from "../../Assets/Images/Home/SenwellLogo.png";

function Header() {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const { t, i18n } = Translation();

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const handleNavItemClick = () => {
    setNavbarOpen(false);
  };

  const changeLanguage = (e) => {
    const selectedLang = e.target.value;
    console.log("selectedLang : ", selectedLang);
    i18n.changeLanguage(selectedLang);
  };
  useEffect(() => {
    const handleScroll = () => {
      setNavbarOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="mobile-icon-section mt-4 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mobile-no">
                <div className="phone-section d-flex">
                  <span>
                    <i className="fa-solid fa-phone fa-xl"></i>
                  </span>
                </div>

                <div className="mobile-no-social-icons">
                  <span className="social-icon">
                    <i
                      className="fa-brands fa-linkedin fa-xl mx-1"
                      title="LinkedIn"
                    ></i>
                  </span>
                  <span className="social-icon">
                    <i
                      className="fa-brands fa-facebook fa-xl mx-1"
                      title="Facebook"
                    ></i>
                  </span>

                  <span className="social-icon">
                    <i
                      className="fa-brands fa-instagram fa-xl mx-1"
                      title="Instagram"
                    ></i>
                  </span>
                  <span className="social-icon">
                    <i
                      className="fa-brands fa-youtube fa-xl mx-1"
                      title="Twitter"
                    ></i>
                  </span>
                  <div className="lang mx-2">
                    <select onChange={changeLanguage} id="language">
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="first-navbar">
        <div id="top-header" className="header-top-bar align-items-center">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="topbar-left">
                <div className="Header-Certified header-item">
                  <span>{t("companyCertification")}</span>
                </div>
                <div className="header-email header-item">
                  <i className="fa-regular fa-envelope"></i>
                  <a href="mailto:info@senwellsys.com">{t("contactEmail")}</a>
                </div>
              </div>

              <div className="top-right header-top-bar">
                <div className="header-phone header-item ">
                  <i className="fa-solid fa-phone"></i>
                  <span>{t("EnquireNow")}:</span>
                  <a href="tel:+91 9175447941">+91 9175447941 </a>
                </div>
                <div className="header-phone header-item ">
                  <div className="lang mx-2">
                    <select onChange={changeLanguage} id="language">
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                    </select>
                  </div>
                </div>
                <ul>
                  <li className="topbar-social">
                    <div className="social-icon ">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/senwell-exports-private-limited/"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100068983571168"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/senwellgroup/"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@senwellgroup"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar navbar-expand-xl p-3  shadow">
        <div className="container-fluid">
          <div className="logo d-flex">
            <img
              src={SenwellLogo}
              className="img-thumbnail rotating-logo"
              alt="Senwell Exports Logo"
            />

            <Link
              to="/"
              className="navbar-brand navbar-brand-custom mx-1"
              style={{ color: "#2d67aa", fontWeight: "600" }}
            >
              Senwell
            </Link>

            <Link
              to="/"
              className="navbar-brand mx-1"
              style={{ color: "#3AC4F2", fontWeight: "600" }}
            >
              Exports
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            onClick={handleNavItemClick}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-5 mx-auto mb-2  mt-2">
              <li className="nav-item mx-1">
                <Link to="/" className="nav-link fw-medium" aria-current="page">
                  {t("Home")}
                </Link>
              </li>
              <li className="nav-item mx-1 dropdown">
                <Link className="nav-link fw-medium">{t("TheCompany")}</Link>
                <ul
                  className="dropdown-menu shadow"
                  onClick={handleNavItemClick}
                >
                  <li className="nav-item1 dropdown">
                    <Link to="/about">{t("AboutUs")}</Link>
                  </li>
                  <li className="nav-item1 dropdown electricDropdown">
                    <Link to="/csr">{t("CSR")}</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-1 dropdown">
                <Link className="nav-link fw-medium" role="button">
                  {t("Product")}
                </Link>
                <ul
                  className="dropdown-menu shadow  rounded aerospace"
                  onClick={handleNavItemClick}
                >
                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/aerospace-industries"
                      className="nav-link fw-medium"
                      role="button"
                    >
                      {t("Aerospace")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/oil-gas"
                      className="nav-link fw-medium"
                      role="button"
                    >
                      {t("OilAndGas")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/energy"
                      className="nav-link fw-medium"
                      role="button"
                    >
                      {t("Energy")}
                    </Link>
                    <ul className="dropdown-menu-second shadow rounded">
                      <li className="nav-item">
                        <Link
                          to="/terminal-blocks"
                          className="nav-link fw-medium"
                        >
                          {t("TerminalBlock")}
                        </Link>
                        <Link
                          to="/fastener-fixing"
                          className="nav-link fw-medium"
                        >
                          {t("FastenersAndFixings")}
                        </Link>
                        <Link
                          to="/electrical-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("ElectricalAccessories")}
                        </Link>

                        <Link
                          to="/brass-electrical-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("BrassElectricalAccessories")}
                        </Link>
                        <Link to="/CableTies" className="nav-link fw-medium">
                          {t("CableGlands")}
                        </Link>

                        <Link
                          to="/coppper-brass-earthing-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("EarthingAccessories")}
                        </Link>

                        <Link to="/CableTies2" className="nav-link fw-medium">
                          {t("CableTies")}
                        </Link>

                        <Link
                          to="/casting-product"
                          className="nav-link fw-medium"
                        >
                          {t("CastingProducts")}
                        </Link>

                        <Link
                          to="/metal-enclosure-component"
                          className="nav-link fw-medium"
                        >
                          {t("MetalEnclosure")}
                        </Link>

                        <Link
                          to="/turned-miled-forged"
                          className="nav-link fw-medium"
                        >
                          {t("TurnedMilledForgedParts")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/quality"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Quality")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/service"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Service")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/blog"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("blogs")}
                </Link>
              </li>{" "}
              <li className="nav-item mx-1">
                <Link
                  to="/news"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("news")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/career"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Career")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/infrastructure"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Infrastructure")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/contact"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Contact_us")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
